import React, { PureComponent, Fragment } from "react";
import Form from "js/pages/Admin/Programa/Campana/Edit/MyForm.js";
import CampanaGraphQL from "js/graphql/resolvers/campana.resolver";
import GruposProductosGraphQL from "js/graphql/resolvers/campanaGrupoProducto.resolver";
import SegmentosGraphQL from "js/graphql/resolvers/segmento.resolver";
import { removeTypename } from "js/utils/Helper";
import { addImageURL } from "js/constants";
import { getUrl } from "js/utils";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        tipo: "SIMPLE"
      },
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      idCampana: this.props.match.params.idCampana,
      fechaInicio: null,
      fechaFin: null,
      fechaCierre: null,
      campanaImages: [],
      campanasInput: [],
      finalCampanasInput: [],
      gruposProductos: []
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    let { id, idCampana } = this.state;

    idCampana = parseInt(idCampana) || idCampana;

    if (idCampana !== "new") {
      await client
        .query({
          query: CampanaGraphQL.queries.getCampanaById,
          fetchPolicy: "network-only",
          variables: {
            campanaLike: {
              idCampana
            }
          }
        })
        .then(res => {
          const campana = res.data.getCampanas.itemsPagina[0];
          const { campanasInput, finalCampanasInput } = this.state;
          let grupoProductos = campana.grupoProductos;

          if (
            campana.campanasRelacionadas &&
            campana.campanasRelacionadas.length !== 0
          ) {
            const campanasRelacionadas = campana.campanasRelacionadas;
            delete campana.campanasRelacionadas;
            campana.campanasRelacionadas = campanasRelacionadas.map(
              campana => campana._id
            );
          }
          this.setState({
            initialValues: {
              ...campana,
              grupoProductos: grupoProductos ? grupoProductos.idGrupo : null,
              fields: campana.puntosPorSegmentos
            },
            fechaInicio: campana.fechaInicio,
            fechaFin: campana.fechaFin,
            fechaCierre: campana.fechaCierre,
            campanaImages: campana.imagenes,
            finalCampanasInput:
              campana.estado === "FINALIZADA"
                ? campanasInput
                : finalCampanasInput
          });
        });
    } else {
      this.setState({
        initialValues: {
          tipo: "SIMPLE"
        }
      });
    }

    await client
      .query({
        query: GruposProductosGraphQL.queries.getGruposProductosInput,
        fetchPolicy: "network-only",
        variables: {
          grupoProductosLike: {
            idPrograma: parseInt(id) || id
          }
        }
      })
      .then(res => {
        const gruposProductos = res.data.getGruposProductosCampana
          ? res.data.getGruposProductosCampana.itemsPagina
          : null;
        this.setState({ gruposProductos: gruposProductos });
      });

    await client
      .query({
        query: CampanaGraphQL.queries.getCampanasInput,
        fetchPolicy: "network-only",
        variables: {
          campanaLike: {
            idPrograma: parseInt(id) || id,
            tipo: "SIMPLE"
          },
          orderBy: "fechaCreacion",
          order: "desc"
        }
      })
      .then(res => {
        const campanas = res.data.getCampanas
          ? res.data.getCampanas.itemsPagina
          : null;

        const finalCampanasInput = [];
        campanas.map(campana => {
          if (campana.estado === "INICIADA") {
            finalCampanasInput.push(campana);
          }
        });
        this.setState({
          campanasInput: campanas,
          finalCampanasInput: finalCampanasInput
        });
      });

    await client
      .query({
        query: SegmentosGraphQL.queries.getSegmentosInput,
        fetchPolicy: "network-only",
        variables: {
          idPrograma: parseInt(id) || id
        }
      })
      .then(res => {
        const segmentos = res.data.getSegmentos ? res.data.getSegmentos : null;

        this.setState({ segmentos });
      });
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, idCampana, campanaImages: imagenes } = this.state;

    let variables = { ...values };
    variables.idPrograma = parseInt(id) || id;
    variables.fechaInicio = this.state.fechaInicio;
    variables.fechaFin = this.state.fechaFin;
    variables.fechaCierre = this.state.fechaCierre;
    variables.imagenes = imagenes;
    variables.puntosPorSegmentos = variables.fields;
    variables.puntosTodos = parseInt(variables.puntosTodos);
    delete variables.fields;
    delete variables.estado;

    if (variables.tipo === "SIMPLE") {
      delete variables.campanasRelacionadas;
    }
    if (variables.tipo === "COMPUESTO") {
      delete variables.grupoProductos;
    }

    await client
      .mutate({
        mutation:
          idCampana === "new"
            ? CampanaGraphQL.mutations.createCampana
            : CampanaGraphQL.mutations.updateCampana,
        variables: {
          campana: { ...removeTypename(variables) }
        }
      })
      .then(res => {
        const mensaje =
          "Campaña " +
          (idCampana === "new" ? "creada" : "modificada") +
          " correctamente";
        this.props.openNotification(mensaje);
        history.push(`/admin/programa/${id}/campanas`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  onChangeImage = image => {
    const formData = new window.FormData();
    formData.append("image", image);
    formData.append("context", "campanas-genericas");
    formData.append("height", 556);
    formData.append("width", 556);
    fetch(addImageURL, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(({ url }) => {
        this.setState(({ campanaImages }) => ({
          campanaImages: campanaImages.concat(getUrl(url))
        }));
      })
      .catch(() => console.error("Hubo un error al cargar la imagen a S3"));
  };

  onRemoveImage = urlToRemove => {
    this.setState(({ campanaImages }) => ({
      campanaImages: campanaImages.filter(url => url !== urlToRemove)
    }));
  };

  handleDateChange = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };

  render() {
    return (
      <Fragment>
        <Form
          id={this.state.id}
          errors={this.state.errors}
          disabled={this.state.disabled}
          initialValues={this.state.initialValues}
          onSubmit={this.submit.bind(this)}
          handleChangeFecha={this.handleDateChange}
          fechaInicio={this.state.fechaInicio}
          fechaFin={this.state.fechaFin}
          campanaImages={this.state.campanaImages}
          onChangeImage={this.onChangeImage.bind(this)}
          onRemoveImage={this.onRemoveImage.bind(this)}
          campanasInput={this.state.campanasInput}
          gruposProductos={this.state.gruposProductos}
          segmentos={this.state.segmentos}
        />
      </Fragment>
    );
  }
}

export default Edit;
