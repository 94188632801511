import { typesWithInput, keysWithInput } from "../../types";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import {
  FormControl,
  Select,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  withStyles
} from "@material-ui/core";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import cx from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import React, { PureComponent } from "react";
import ReorderIcon from "@material-ui/icons/Reorder";
import styles from "../styles.js";

const DragHandle = SortableHandle(() => (
  <ReorderIcon style={{ cursor: "move" }} />
));

const SortableItem = SortableElement(
  ({ row, classes, onClick, disableEdit }) => {
    return (
      <TableRow style={{ zIndex: "10" }}>
        <TableCell
          className={cx(classes.align, classes.columnWidth100, classes.border)}
        >
          <div className={classes.name}>
            <DragHandle />
            <div className={classes.nameValue}>
              <TextField
                id="label"
                value={row.label || ""}
                onChange={e => onClick(e, row.key)}
                error={!row.label || row.labelHasError}
                disabled={row.disableEdit || false}
              />
            </div>
          </div>
        </TableCell>
        <TableCell className={cx(classes.align, classes.columnWidth100)}>
          <FormControl className={classes.formControl}>
            <Select
              name="tipo"
              value={row.tipo}
              className={classes.columnWidthInput}
              onChange={e => onClick(e, row.key)}
              disabled={row.disableEdit || false}
            >
              <MenuItem value={"Texto"}>Texto</MenuItem>
              <MenuItem value={"Numerico"}>Número</MenuItem>
              <MenuItem value={"Monto"}>Monto</MenuItem>
              <MenuItem value={"Lista"}>Lista</MenuItem>
              <MenuItem value={"Lista_Multiple"}>Lista múltiple</MenuItem>
              <MenuItem value={"Lista_Autocomplete"}>
                Lista autocompletable
              </MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell
          className={cx(classes.align, classes.columnWidth100, classes.border)}
        >
          {(typesWithInput.includes(row.tipo) ||
            keysWithInput.includes(row.key)) && (
            <TextField
              id="value"
              className={cx(classes.textField, classes.columnWidthInput)}
              value={row.value}
              onChange={e => onClick(e, row.key, row.tipo)}
              disabled={disableEdit}
              autoComplete="off"
            />
          )}
        </TableCell>
      </TableRow>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, classes, onClick, disabled }) => {
    return (
      <TableBody>
        {items.map((row, index) => (
          <SortableItem
            index={index}
            key={`item-${index}`}
            classes={classes}
            row={row}
            onClick={onClick}
            disableEdit={disabled}
            disabled={disabled}
          />
        ))}
      </TableBody>
    );
  }
);

class SortableComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ items: props.data });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    const { sortEnded } = this.props;

    this.setState({
      items: arrayMove(items, oldIndex, newIndex)
    });
    sortEnded(this.state.items);
  };
  render() {
    const { items } = this.state;
    const { classes, onClick, disabled } = this.props;

    return (
      <SortableList
        items={items}
        classes={classes}
        onClick={onClick}
        onSortEnd={this.onSortEnd}
        useDragHandle={true}
        disabled={disabled}
      />
    );
  }
}

export default withApollo(withRouter(withStyles(styles)(SortableComponent)));
