import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Table from "js/components/Shared/Tabla";
import advancedFilter from "./advancedFilter";
import { USER_ROLES as Roles } from "js/models/User";
import AppQraphQL from "js/graphql/resolvers/cargaFacturaPorPuntos.resolver";
import FacturaPorPuntosQraphQL from "js/graphql/resolvers/parametrosCargaFacturaPorPuntos.resolver";
import { verFactura } from "js/components/Shared/Tabla/ActionCells";
import { setGruposSKUs } from "js/redux/campana/campana.actions";
import { Estado } from "js/components/Shared/Tabla/TableCells";
import AuthService from "js/utils/AuthService";
import capitalize from "lodash/capitalize";
import { _t } from "js/utils/TranslationService";
import Fila from "js/components/Fila";
import {
  fetchParametrosVisuales,
  getBanners,
  cleanText
} from "js/utils/helpers/FacturaPorPuntos.helpers.js";

const defaultHeader = (id, btnsNextTitle) => {
  return {
    title: AuthService.hasPerms([Roles.MODERACION_FACTURA_POR_PUNTOS_W])
      ? _t("Moderación de facturas")
      : _t("Facturas enviadas"),
    btnsNextTitle,
    export: {
      path: "listaDeFacturasPorPuntos",
      perms: [Roles.MODERACION_FACTURA_POR_PUNTOS_EXPORT],
      tooltip: "Exportar lista de facturas"
    }
  };
};

const defaultSort = { order: "desc", orderBy: "fechaCreacion" };

const columnDataAdmin = [
  {
    id: "usuario",
    label: _t("Usuario solicitante"),
    type: "custom",
    parser: usuario => (usuario ? usuario.username : "--")
  },
  {
    id: "usuarioModerador",
    label: _t("Moderador"),
    type: "custom",
    parser: usuario => (usuario ? usuario.username : "--")
  },
  {
    id: "actions",
    label: _t("Acciones")
  }
];

const columnDataUser = [
  {
    id: "actions",
    label: _t("Ver factura")
  }
];

class Tabla extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      parametrosFacturaPorPuntos: [],
      columnData: [
        //{ id: "idCargaFactura", label: "#" },
        {
          id: "fechaFactura",
          label: _t("Fecha de factura"),
          type: "date"
        },
        { id: "numeroFactura", label: _t("Nº Factura") }
      ],
      parametrosVisuales: {},
      banners: {},
      inBackOffice:
        window.location.pathname.indexOf("admin") !== -1 ? true : false
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      },
      client
    } = this.props;

    const idPrograma = id || localStorage.getItem("idPrograma");

    let parametrosVisuales = await fetchParametrosVisuales(idPrograma, client);

    let banners = {};

    if (parametrosVisuales && parametrosVisuales.banners) {
      banners = getBanners(parametrosVisuales.banners, false);

      if (banners) {
        banners.contenidos = cleanText(banners);
      }
    }

    if (parametrosVisuales) {
      this.setState({ banners: banners ? banners : {} });
    }

    await this.columnDataBuilder();
  }

  fetchParametrosFacturaPorPuntos = async () => {
    const {
      match: {
        params: { id }
      },
      client
    } = this.props;

    let parametrosFacturaPorPuntosUpdated = [];
    const { data } = await client.query({
      query: FacturaPorPuntosQraphQL.queries.getParametrosFacturaPorPuntosById,
      variables: {
        idPrograma: parseInt(id) || parseInt(localStorage.getItem("idPrograma"))
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache"
    });

    if (data) {
      parametrosFacturaPorPuntosUpdated = data.getParametrosFacturaPorPuntos
        .map(head => {
          return { id: head.key, label: head.label };
        })
        .filter(head => {
          return ![
            "tasaDeAcreditacion",
            "limitePorFactura",
            "limiteDiario",
            "limiteMensual",
            "requiereModeracion",
            "requiereComprobante",
            "cargaTerceros",
            "incrementos",
            "regexNroFactura",
            "inicioTratamiento"
          ].includes(head.id);
        });
    }
    this.setState({
      parametrosFacturaPorPuntos: parametrosFacturaPorPuntosUpdated
    });
  };

  columnDataBuilder = async () => {
    let { columnData } = this.state;

    await this.fetchParametrosFacturaPorPuntos();
    const { parametrosFacturaPorPuntos } = this.state;
    columnData.push(...parametrosFacturaPorPuntos);
    columnData.push(
      {
        id: "estado",
        label: _t("Estado"),
        type: "custom",
        parser: estado => (
          <Estado
            estado={capitalize(
              estado === "PENDIENTE_APROBACION"
                ? "PENDIENTE APROBACION"
                : estado
            )}
          />
        )
      },
      {
        id: "puntosAAcreditar",
        label: _t("Puntos acreditados")
      }
    );
    AuthService.hasPerms([Roles.MODERACION_FACTURA_POR_PUNTOS_W])
      ? columnData.push(...columnDataAdmin)
      : columnData.push(...columnDataUser);
    this.setState({ columnData });
  };
  render() {
    const { columnData, banners, inBackOffice } = this.state;
    const cutsomButtons = [];

    const {
      match: {
        params: { id: idPrograma }
      }
    } = this.props;

    const verFacturaButton = params => ({
      ...verFactura({
        openNotification: this.props.openNotification,
        ...params,
        perms: [Roles.MODERACION_FACTURA_POR_PUNTOS_R]
      })
    });

    cutsomButtons.push(verFacturaButton);

    const getFacturaPorPuntos = data => {
      if (data) {
        let itemsPagina = data.getFacturaPorPuntos.itemsPagina;
        itemsPagina = itemsPagina.map(item => {
          let detalle = JSON.parse(item.detalle);
          let factura = { ...item, ...detalle };
          delete factura.detalle;
          return factura;
        });
        return {
          itemsPagina: itemsPagina,
          infoPagina: data.getFacturaPorPuntos.infoPagina
        };
      } else {
        return {
          itemsPagina: [],
          infoPagina: {}
        };
      }
    };

    const {
      match: {
        params: { id }
      },
      client
    } = this.props;

    return (
      <>
        {banners &&
          banners.visible &&
          !inBackOffice && (
            <div style={{ marginBottom: "1em" }}>
              <Fila {...banners} baseLink={"Banners Performance"} />
            </div>
          )}
        <Table
          storage={"FiltroCodigosCargados"}
          query={AppQraphQL.queries.getFacturaPorPuntos}
          defaultHeader={defaultHeader(parseInt(id))}
          advancedFilter={advancedFilter}
          sort={defaultSort}
          columnData={columnData}
          getTipo={getFacturaPorPuntos}
          showPagination={true}
          actionsCustom={cutsomButtons}
          pathId={"_id"}
          idPrograma={idPrograma}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGruposSKUs: grupos => dispatch(setGruposSKUs(grupos))
});

export default connect(
  null,
  mapDispatchToProps
)(Tabla);
