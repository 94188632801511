import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withOnSaveLog } from "./withKibanaTracker.js";
import { cx } from "emotion";
import { withApollo } from "react-apollo";
import { openNewWindow, openNewTab, changeTab, trackLinkClick } from "js/utils";
import ApiTokenGraphQL from "js/graphql/resolvers/apiToken.resolver";
import UsuarioGraphQL from "js/graphql/resolvers/usuario.resolver";
import AuthService from "js/utils/AuthService";

const getOnClick = (link, baseLink, target, openLogic, client, origen) => {
  if (
    (link && typeof link === "string" && link.indexOf("<token>") !== -1) ||
    (target === "_blank" || target === "new")
  ) {
    return {
      onClick: e => e.preventDefault() || openLogic(link, baseLink, target),
      target: ""
    };
  } else {
    return {
      // chequear js/utils para ver que sirve esta función
      onClick: e => trackLinkClick(link, client, origen)
    };
  }
};

class LinkContentWrapper extends PureComponent {
  async openLinkWithToken(link, baseLink, target) {
    const { client, shouldLog, origen } = this.props;
    // chequear js/utils para ver que sirve esta función
    await trackLinkClick(link, client, origen);
    const idPrograma = parseInt(AuthService.getIdPrograma());
    const idUsuario = parseInt(AuthService.getIdUsuario());
    let finalLink = "";

    if (link && link.indexOf("<token>") !== -1) {
      // TODO: Buscar la forma de hacer esta comprobación genérica
      if (link.indexOf("prode") !== -1 || link.indexOf("penca") !== -1) {
        await client
          .query({
            query: ApiTokenGraphQL.queries.getApiTokenTerceroUser,
            variables: {
              apiTokenTerceroLike: {
                idPrograma,
                idUsuario
              }
            }
          })
          .then(res => {
            if (res.data.getApiTokenTerceroUser)
              finalLink = link.replace(
                "<token>",
                res.data.getApiTokenTerceroUser.token
              );
          })
          .catch(error => {
            console.log("error", error);
          });
      } else {
        const { data } = await client.mutate({
          mutation: UsuarioGraphQL.mutations.generarTokenParaUsoExterno,
          variables: {
            idPrograma: idPrograma
          }
        });
        if (data.generarTokenParaUsoExterno) {
          const token = data.generarTokenParaUsoExterno;
          finalLink = link.replace("<token>", token);
        }
      }
    } else {
      finalLink = link;
    }

    // Abrir con token en otra pestaña
    if (finalLink && target === "_blank") {
      openNewTab(finalLink);
    }

    // Abrir con token en otra ventana
    if (finalLink && target === "new") {
      openNewWindow(finalLink);
    }

    // Abrir con token en la misma pestaña
    if (finalLink && target !== "_blank" && target !== "new") {
      changeTab(finalLink);
    }

    if (!finalLink) {
      shouldLog({ url: link, modulo: `${baseLink}+${link}` });
    }
  }

  render() {
    const {
      link,
      classes,
      children,
      style,
      target,
      baseLink = "Landing",
      client,
      origen
    } = this.props;

    return link !== null ? (
      /^(https|http|mailto)?:(\/\/)?/.test(link) ? (
        <a
          href={link}
          rel="noopener noreferrer"
          style={style}
          className={cx(classes.link, { [classes.noLink]: !link })}
          {...getOnClick(
            link,
            baseLink,
            target,
            this.openLinkWithToken.bind(this),
            client,
            origen
          )}
        >
          <div className={classes.div} variant="body1">
            {children}
          </div>
        </a>
      ) : (
        <Link
          to={link}
          style={style}
          className={cx(classes.link, { [classes.noLink]: !link })}
          {...getOnClick(
            link,
            baseLink,
            target,
            this.openLinkWithToken.bind(this),
            client,
            origen
          )}
        >
          <div className={classes.div} variant="body1">
            {children}
          </div>
        </Link>
      )
    ) : (
      ""
    );
  }
}

LinkContentWrapper.defaultProps = {
  track: false,
  shouldLog: () => {},
  link: "#",
  classes: {},
  children: "",
  style: undefined,
  origen: ""
};

LinkContentWrapper.propTypes = {
  track: PropTypes.bool,
  shouldLog: PropTypes.func,
  link: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.any,
  style: PropTypes.object,
  origen: PropTypes.string
};

export default withOnSaveLog(withApollo(LinkContentWrapper));
