import React from "react";
import { sortableHandle } from "react-sortable-hoc";
import Icon from "@material-ui/core/Icon";

const DragHandle = ({ classes }) => (
  <Icon className={classes.icon} color="secondary">
    drag_handle
  </Icon>
);

export default sortableHandle(DragHandle);
