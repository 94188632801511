import React, { PureComponent } from "react";
import Form from "js/pages/Admin/Programa/CanalRetail/Edit/MyForm.js";
import AppQraphQL from "js/graphql/resolvers/canalRetail.resolver";
import { removeTypename } from "js/utils/Helper";

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        idPrograma: this.props.match.params.id
      },
      disabled: false,
      errors: null,
      id: this.props.match.params.id,
      codigoCanal: this.props.match.params.codigoCanal
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { id, codigoCanal } = this.state;
    if (codigoCanal !== "new") {
      await client
        .query({
          query: AppQraphQL.queries.getCanalRetailById,
          fetchPolicy: "network-only",
          variables: {
            canalLike: {
              codigo: codigoCanal
            }
          }
        })
        .then(res => {
          const canal = res.data.getCanalesRetail.itemsPagina[0];
          this.setState({
            initialValues: {
              ...canal
            }
          });
        });
    } else {
      this.setState({
        initialValues: {
          idPrograma: id,
          estado: "ACTIVO"
        }
      });
    }
  }

  async submit(values) {
    const { client, history } = this.props;
    const { id, codigoCanal } = this.state;
    const variables = { ...values };

    await client
      .mutate({
        mutation:
          codigoCanal === "new"
            ? AppQraphQL.mutations.createCanalRetail
            : AppQraphQL.mutations.updateCanalRetail,
        variables: {
          canal: { ...removeTypename(variables) }
        }
      })
      .then(res => {
        const mensaje =
          "Canal " +
          (codigoCanal === "new" ? "creado" : "modificado") +
          " correctamente";
        this.props.openNotification(mensaje);
        history.push(`/admin/programa/${id}/canales-retail`);
      })
      .catch(errors => {
        const arr = [];
        arr.push({ message: "Error inesperado" });
        this.setState({
          errors: errors.graphQLErrors.length !== 0 ? errors.graphQLErrors : arr
        });
        return false;
      });
  }

  handleDateChange = key => date => {
    this.setState({
      [key]: date.toISOString()
    });
  };

  render() {
    return (
      <Form
        id={this.state.id}
        codigoCanal={this.state.codigoCanal}
        errors={this.state.errors}
        disabled={this.state.disabled}
        initialValues={this.state.initialValues}
        onSubmit={this.submit.bind(this)}
      />
    );
  }
}

export default Edit;
