import {
  CAMBIAR_CANTIDAD,
  DECREMENTAR_CANTIDAD,
  INCREMENTAR_CANTIDAD,
  CANJEAR_FAIL,
  CHANGE_REQUERIDO,
  CHANGE_SUCURSAL,
  CLEAN_PRODUCTO_STATE,
  CLOSE_DIALOG_CANJE,
  CLOSE_NOTIFICATION,
  LOAD_PRODUCTO_STATE,
  OPEN_DIALOG_CANJE,
  SET_REQUERIDOS,
  REQUIRE_DATA_USER,
  TOGGLE_FAVORITO_ERROR,
  TOGGLE_FAVORITO_SUCCESS,
  TOGGLE_FAVORITO,
  UPDATE_STORE_DATOS_PERFIL
} from "./constants";
import client from "js/App/client.graphql.js";
import FavoritoQraphQL from "js/graphql/resolvers/favorito.resolver";
import CanjeQraphQL from "js/graphql/resolvers/canje.resolver";
import { merge, mergeWith, get } from "lodash";
import { _t } from "js/utils/TranslationService";

const validateMultipleFields = fields =>
  fields.reduce((acc, curr) => validateSingleFiled(curr) && acc, true);

const validateSingleFiled = field =>
  field !== undefined && field !== null && field !== "";

const isValid = (datosDeCanje, datosRequeridos) => {
  return datosRequeridos
    .map(r => {
      switch (r.key) {
        case "entrega":
          return validateMultipleFields(
            Object.values(JSON.parse(datosDeCanje[r.key]))
          );
        default:
          return validateSingleFiled(datosDeCanje[r.key]);
      }
    })
    .reduce((acc, curr) => curr && acc, true);
};

const getDatosEntrega = (datosDeCanje, datosPerfil) => {
  const requeridosObj =
    typeof datosDeCanje["entrega"] === "string"
      ? JSON.parse(datosDeCanje["entrega"])
      : datosDeCanje["entrega"];
  const datosDeCanjeObj = JSON.parse(datosPerfil["entrega"]);
  let datosEntrega =
    requeridosObj.direccion === "" &&
    requeridosObj.cp === "" &&
    requeridosObj.idPais === "" &&
    requeridosObj.idZona === "" &&
    requeridosObj.idLocalidad === ""
      ? datosDeCanjeObj
      : requeridosObj;
  datosEntrega = mergeWith(
    {},
    { direccion: "", cp: "", idPais: "", idZona: "", idLocalidad: "" },
    datosEntrega,
    (a, b) => (b ? b : a)
  );
  return JSON.stringify({
    direccion: datosEntrega.direccion,
    cp: datosEntrega.cp,
    idPais: datosEntrega.idPais,
    idZona: datosEntrega.idZona,
    idLocalidad: datosEntrega.idLocalidad
  });
};
export const productoActions = ({ dispatch, ownProps }) => ({
  abrirCanjePopup: () => dispatch({ type: OPEN_DIALOG_CANJE }),
  cerrarCanjePopup: () => dispatch({ type: CLOSE_DIALOG_CANJE }),
  cleanProductoState: () => dispatch({ type: CLEAN_PRODUCTO_STATE }),
  loadProductoState: eleccionProducto =>
    dispatch({ type: LOAD_PRODUCTO_STATE, eleccionProducto }),
  cambiarCantidad: cantidad => dispatch({ type: CAMBIAR_CANTIDAD, cantidad }),
  decrementarCantidad: cantidad =>
    dispatch({ type: DECREMENTAR_CANTIDAD, cantidad }),
  incrementarCantidad: cantidad =>
    dispatch({ type: INCREMENTAR_CANTIDAD, cantidad }),
  toggleFavorito: idEleccionProducto => () => {
    dispatch({ type: TOGGLE_FAVORITO, idEleccionProducto });
    client
      .mutate({
        mutation: FavoritoQraphQL.mutations.setFavorito,
        errorPolicy: "all",
        variables: {
          favoritoToggle: {
            idEleccionProducto: idEleccionProducto
          }
        }
      })
      .then(res => {
        const favorito = get(res, "data.toggleFavorito.favorito", false);
        dispatch({
          type: TOGGLE_FAVORITO_SUCCESS,
          favorito,
          mensaje:
            favorito === true
              ? `${_t("Has agregado este producto a tu lista favoritos")}`
              : `${_t("Has quitado este producto a su lista favoritos")}`
        });
      })
      .catch(e => {
        dispatch({
          type: TOGGLE_FAVORITO_ERROR,
          error: `${_t(e.message)}`,
          mensaje: `${_t(
            "No se pudo actualizar este producto a su lista favoritos"
          )}`
        });
      });
  },
  closeNotification: () => dispatch({ type: CLOSE_NOTIFICATION }),
  requestCanje: (stateParaCanje, history) => () => {
    // dispatch({ type: CANJEAR, stateParaCanje });
    const { datosDeCanje, datosPerfil, datosRequeridos, self } = stateParaCanje;
    self.setState({
      canjeando: true
    });

    const entrega = datosDeCanje.entrega
      ? getDatosEntrega(datosDeCanje, datosPerfil)
      : null;

    const variables = datosRequeridos.reduce(
      (acc, dato) =>
        dato.key !== "entrega"
          ? merge(acc, { [dato.key]: datosDeCanje[dato.key] })
          : merge(acc, { [dato.key]: entrega }),
      {}
    );
    const isValidRequeridos = isValid(variables, datosRequeridos);

    const canjeVariables = {
      idEleccionProducto: stateParaCanje.idEleccionProducto,
      cantidad: stateParaCanje.cantidad,
      observaciones: datosDeCanje.observaciones,
      datosDeCanje: {
        ...variables
      }
    };

    if (!isValidRequeridos) {
      dispatch({
        type: REQUIRE_DATA_USER,
        error: "Faltan requeridos"
      });
      self.setState({
        canjeando: false
      });
    } else {
      client
        .mutate({
          mutation: CanjeQraphQL.mutations.canjearProducto,
          errorPolicy: "all",
          refetchQueries: ["getMiUsuario"],
          variables: { canje: canjeVariables }
        })
        .then(res => {
          if (!res.errors) {
            const idCanje = res.data.canjearProducto.idCanje;
            history.push(`/canje/${idCanje}`);
          } else {
            throw res.errors[0];
          }
        })
        .catch(e => {
          self.setState({
            canjeando: false
          });
          dispatch({ type: CANJEAR_FAIL, error: e.message });
        });
    }
  },
  changeRequeridoString: key => string =>
    dispatch({ type: CHANGE_REQUERIDO, data: { [key]: string } }),
  changeRequerido: key => event => {
    if (key === "sucursal") {
      dispatch({ type: CHANGE_SUCURSAL, idSucursal: event.target.value });
    }
    dispatch({ type: CHANGE_REQUERIDO, data: { [key]: event.target.value } });
  },
  setRequeridos: requeridos =>
    dispatch({ type: SET_REQUERIDOS, data: requeridos }),
  changeSucursal: event =>
    dispatch({ type: CHANGE_SUCURSAL, idSucursal: event.target.value }),
  updateDatosPerfil: data =>
    dispatch({
      type: UPDATE_STORE_DATOS_PERFIL,
      data
    })
});

export default productoActions;
