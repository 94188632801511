import gql from "graphql-tag";

export const getPaginaCampanas = gql`
  query getCampanas(
    $page: Int!
    $rowsPerPage: Int!
    $order: Order!
    $orderBy: CampanaColumn!
    $idPrograma: Int
    $nombre: String
    $tipo: String
    $fechaInicio: Date
    $fechaFin: Date
    $puntosTodos: Int
    $estado: EstadoCampana
  ) {
    getCampanas(
      pagina: { numeroPagina: $page, cantidadPorPagina: $rowsPerPage }
      campanaOrder: { column: $orderBy, order: $order }
      campanaLike: {
        idPrograma: $idPrograma
        nombre: $nombre
        puntosTodos: $puntosTodos
        estado: $estado
        tipo: $tipo
      }
      campanaRange: {
        fechaInicio: { min: $fechaInicio }
        fechaFin: { max: $fechaFin }
      }
    ) {
      infoPagina {
        total
        numeroPagina
        cantidadPaginas
      }
      itemsPagina {
        fechaCreacion
        idCampana
        idPrograma
        _id
        nombre
        fechaInicio
        fechaFin
        fechaCierre
        puntosTodos
        grupoProductos {
          nombre
        }
        estado
        tipo
      }
    }
  }
`;

export const getCampanaById = gql`
  query getCampanas($campanaLike: CampanaSearch, $pagina: Pagina) {
    getCampanas(campanaLike: $campanaLike, pagina: $pagina) {
      itemsPagina {
        idCampana
        idPrograma
        nombre
        descripcion
        fechaInicio
        fechaFin
        fechaCierre
        puntosTodos
        grupoProductos {
          _id
          idGrupo
          nombre
        }
        estado
        imagenes
        tipo
        campanasRelacionadas {
          _id
          idCampana
          nombre
        }
        puntosPorSegmentos {
          _id
          idSegmento
          puntos
        }
      }
    }
  }
`;

export const getCampanasInput = gql`
  query getCampanas(
    $campanaLike: CampanaSearch
    $orderBy: CampanaColumn!
    $order: Order!
  ) {
    getCampanas(
      campanaLike: $campanaLike
      campanaOrder: { column: $orderBy, order: $order }
    ) {
      itemsPagina {
        _id
        idCampana
        nombre
        estado
      }
    }
  }
`;

export const createCampana = gql`
  mutation createCampana($campana: CampanaCreate) {
    createCampana(campana: $campana) {
      idCampana
    }
  }
`;

export const updateCampana = gql`
  mutation updateCampana($campana: CampanaUpdate) {
    updateCampana(campana: $campana) {
      idCampana
    }
  }
`;

export const importarCampanas = gql`
  mutation importarCampanas($file: Upload!, $idPrograma: Int!) {
    importCampanas(file: $file, idPrograma: $idPrograma)
  }
`;

export const getParametrosCampana = gql`
  query getParametrosCampana($idPrograma: Int!) {
    getParametrosCampana(idPrograma: $idPrograma) {
      asignacionPuntos
      imagenRequerida
      targetsPorUsuarios {
        tipo
        cantidadTargets
        minTargets
        maxTargets
      }
      objetivosPorCampana
      awsUrl
      parametrosVisuales
      puntosPorExceso {
        tipo
        targetExcedidoPor
        puntosFijos
        targetVariable
        puntosVariables
      }
      conceptoGeneral
      conceptoTarget
      conceptoObjetivo
      conceptoCampana
    }
  }
`;

export const getFormaSumar = gql`
  query getParametrosCampana($idPrograma: Int!) {
    getParametrosCampana(idPrograma: $idPrograma) {
      asignacionPuntos
    }
  }
`;

export const updateParametrosCampana = gql`
  mutation updateParametrosCampana($parametros: ParametrosCampanaCreate) {
    updateParametrosCampana(parametros: $parametros) {
      idPrograma
    }
  }
`;

export const createTestPerformance = gql`
  mutation createTestPerformance($idPrograma: Int!) {
    createTestPerformance(idPrograma: $idPrograma)
  }
`;

export default {
  queries: {
    getPaginaCampanas,
    getCampanaById,
    getCampanasInput,
    getParametrosCampana,
    getFormaSumar
  },
  mutations: {
    createCampana,
    updateCampana,
    importarCampanas,
    updateParametrosCampana,
    createTestPerformance
  }
};
