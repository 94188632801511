import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { cx, css } from "emotion";
import { NavLink } from "react-router-dom";
import AuthService from "js/utils/AuthService";
import { USER_ROLES as Roles } from "js/models/User";
import { openNewWindow, openNewTab, changeTab, trackLinkClick } from "js/utils";
import ApiTokenGraphQL from "js/graphql/resolvers/apiToken.resolver";
import UsuarioGraphQL from "js/graphql/resolvers/usuario.resolver";

const getOnClick = ({ target, link }, openLogic, client) => {
  if (
    (link && link.indexOf("<token>") !== -1) ||
    (target === "_blank" || target === "new")
  ) {
    return {
      onClick: e => e.preventDefault() || openLogic(link, target),
      target: ""
    };
  } else {
    return {
      // chequear js/utils para ver que sirve esta función
      onClick: e => trackLinkClick(link, client, "menu")
    };
  }
};

class Navbar extends PureComponent {
  async openLinkWithToken(link, target) {
    const { client } = this.props;
    // chequear js/utils para ver que sirve esta función
    await trackLinkClick(link, client, "menu");
    const idPrograma = parseInt(AuthService.getIdPrograma());
    const idUsuario = parseInt(AuthService.getIdUsuario());
    if (!idUsuario || !idPrograma)
      return this.props.openNotification("Inicia sesión.");

    if (link && link.indexOf("<token>") !== -1) {
      // TODO: Buscar la forma de hacer esta comprobación genérica
      if (link.indexOf("prode") !== -1 || link.indexOf("penca") !== -1) {
        await client
          .query({
            query: ApiTokenGraphQL.queries.getApiTokenTerceroUser,
            variables: {
              apiTokenTerceroLike: {
                idPrograma,
                idUsuario
              }
            }
          })
          .then(res => {
            if (res.data.getApiTokenTerceroUser)
              this.redirect(
                link.replace("<token>", res.data.getApiTokenTerceroUser.token),
                target
              );
          })
          .catch(error => {
            this.props.openNotification(error.message);
          });
      } else {
        const { data } = await client.mutate({
          mutation: UsuarioGraphQL.mutations.generarTokenParaUsoExterno,
          variables: {
            idPrograma: idPrograma
          }
        });
        if (data.generarTokenParaUsoExterno) {
          this.redirect(
            link.replace("<token>", data.generarTokenParaUsoExterno),
            target
          );
        }
      }
    } else {
      this.redirect(link, target);
    }
  }

  redirect(finalLink, target) {
    // Abrir con token en otra pestaña
    if (finalLink && target === "_blank") {
      openNewTab(finalLink);
    }

    // Abrir con token en otra ventana
    if (finalLink && target === "new") {
      openNewWindow(finalLink);
    }

    // Abrir con token en la misma pestaña
    if (finalLink && target !== "_blank" && target !== "new") {
      changeTab(finalLink);
    }
  }

  render() {
    const { classes, programa, client } = this.props;
    const { menu } = programa.template.header;
    const linkItems = menu.navbar.items;

    const styles = {
      menu: {
        minHeight: 46,
        ...menu.navbar[menu.tipo],
        backgroundColor: menu.navbar.css.backgroundColor,
        borderTop:
          menu.tipo === "below"
            ? `1px solid ${menu.navbar.below.borderTop}`
            : null,
        height:
          menu.tipo !== "burger" && menu.navbar.height > 64
            ? menu.navbar.height
            : null,
        maxHeight: menu.tipo === "inside" ? menu.toolbar.height : null
      },
      links: {}
    };

    const classNames = cx({
      [classes.navbar]: true,
      [classes[menu.navbar.below.align]]: menu.tipo === "below"
    });

    const itemStyle = Object.assign({}, menu.navbar.css);
    delete itemStyle.backgroundColor;
    delete itemStyle.color;
    itemStyle[" > a:hover"].color += "!important";
    const isActive = link => (match, location) => {
      if (match) return true;
      if (link.includes("?")) {
        const { pathname, search } = location;
        const splitted = link.split("?");
        return pathname === splitted[0] && search === `?${splitted[1]}`;
      }
      return location.pathname === link;
    };

    return (
      <div style={styles.menu}>
        <div className={classNames}>
          <div
            className={cx({
              [classes.items]: true,
              [css(menu.navbar.css)]: true,
              [css({ ...this.props.css })]: true
            })}
          >
            {linkItems.map((contenido, index) => {
              const perms = (contenido.perms || []).map(r => Roles[r]);
              if (perms.length && !AuthService.hasPerms(perms)) return null;
              return /^(https|http|mailto)?:(\/\/)?/.test(contenido.link) ? (
                <a
                  href={contenido.link}
                  key={`${contenido.link}-${index}`}
                  className={cx(classes.link, menu.navbar.css)}
                  rel="noopener noreferrer"
                  {...getOnClick(
                    contenido,
                    this.openLinkWithToken.bind(this),
                    client
                  )}
                >
                  <span className={css(itemStyle)}>{contenido.texto}</span>
                </a>
              ) : (
                <NavLink
                  activeClassName={classes.active}
                  to={contenido.link}
                  className={cx(classes.link, menu.navbar.css)}
                  key={`${contenido.link}-${index}`}
                  isActive={isActive(contenido.link)}
                  strict={false}
                  target={contenido.target === "_blank" ? contenido.target : ""}
                  // {...getOnClick(contenido, this.openLinkWithToken.bind(this))}
                >
                  <span className={css(itemStyle)}>{contenido.texto}</span>
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Navbar;
