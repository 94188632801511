import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LegalesIcon from "@material-ui/icons/AccountBalance";
import NotificacionesIcon from "@material-ui/icons/NotificationsActive";
import IdiomasIcon from "@material-ui/icons/Language";
import PerfilIcon from "@material-ui/icons/AccountCircle";
import SegmentosIcon from "@material-ui/icons/Notes";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LocalMailIcon from "@material-ui/icons/Mail";
import PersonIcon from "@material-ui/icons/Person";
import SupervisorItemIcon from "@material-ui/icons/SupervisedUserCircle";
import Store from "@material-ui/icons/Store";
import Receipt from "@material-ui/icons/Receipt";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import FlagIcon from "@material-ui/icons/Flag";
import ViewListIcon from "@material-ui/icons/ViewList";
import LinkIcon from "@material-ui/icons/Link";
import AccessTime from "@material-ui/icons/AccessTime";
import ApiIcon from "@material-ui/icons/Stars";
import {
  EyeSettingsOutline,
  PageLayoutBody,
  HomeOutline,
  StarCircleOutline
} from "mdi-material-ui";

import MiProgramaPerms from "js/pages/Admin/Programa/Gestion/perms";
import { USER_ROLES as Roles } from "js/models/User";
import { cloneDeep } from "lodash";

const hasLink = (arr, link) => {
  if (!arr) {
    return false;
  }
  let hasLink = false;
  arr.forEach(item => {
    if (item.link === link) {
      hasLink = true;
    }
  });
  return hasLink;
};

const menuProgramaLinks = [
  [
    {
      link: "gestion",
      texto: "Gestión",
      icon: <SettingsIcon />,
      perms: [],
      optional: MiProgramaPerms
    },
    {
      link: "cuenta_corriente",
      texto: "CC Programa",
      icon: <WalletIcon />,
      perms: [Roles.MOVIMIENTO_PROGRAMA_R]
    },
    {
      link: "usuario",
      texto: "Usuarios",
      icon: <PersonIcon />,
      perms: [Roles.USUARIO_R]
    },
    {
      link: "cc-usuarios",
      texto: "CC Usuarios",
      icon: <WalletIcon />,
      perms: [Roles.MOVIMIENTO_USUARIO_R]
    },
    {
      link: "catalogo",
      texto: "Catálogo",
      icon: <LocalMailIcon />,
      perms: [Roles.PRODUCTO_CATALOGO_R]
    },
    {
      link: "canjes",
      texto: "Canjes",
      icon: <LocalShippingIcon />,
      perms: [Roles.CANJE_R]
    }
  ],
  [
    {
      link: `segmentos`,
      texto: "Segmentos",
      icon: <SegmentosIcon />,
      perms: [Roles.PROGRAMA_W]
    },
    {
      link: `perfil`,
      texto: "Datos de perfil",
      icon: <PerfilIcon />,
      perms: [Roles.DEFINICION_PERFIL_R]
    },
    {
      link: `notificaciones`,
      texto: "Notificaciones",
      icon: <NotificacionesIcon />,
      perms: [Roles.PROGRAMA_W]
    },
    {
      link: `legales`,
      texto: "Legales",
      icon: <LegalesIcon />,
      perms: [Roles.PROGRAMA_W]
    },
    {
      link: "template-builder-2/template",
      texto: "Template",
      icon: <EyeSettingsOutline />,
      perms: [Roles.PROGRAMA_W]
    },
    {
      link: "template-builder-2/layout",
      texto: "Layout",
      icon: <PageLayoutBody />,
      perms: [Roles.PROGRAMA_W]
    },
    {
      link: "template-builder-2/landing",
      texto: "Landing",
      icon: <HomeOutline />,
      perms: [Roles.PROGRAMA_W]
    },
    {
      link: "template-builder-2/destacados",
      texto: "Destacados",
      icon: <StarCircleOutline />,
      perms: [Roles.PROGRAMA_W]
    },
    {
      link: `idioma`,
      texto: "Idiomas",
      icon: <IdiomasIcon />,
      perms: [Roles.IDIOMA_R]
    },
    {
      link: "roles",
      texto: "Roles",
      icon: <SupervisorItemIcon />,
      perms: [Roles.ROL_R]
    }
  ]
];

export const getMenuPrograma = modulosPrograma => {
  let links = cloneDeep(menuProgramaLinks);
  if (modulosPrograma.length !== 0) {
    modulosPrograma.forEach(modulo => {
      if (modulo.estado === "ACTIVO") {
        if (
          modulo.nombreGrupo.toLowerCase() === "unilever" &&
          !hasLink(links[0], "campanas-unilever")
        ) {
          links[0].push({
            link: "campanas-unilever",
            texto: "Campañas",
            icon: <FlagIcon />,
            perms: [Roles.CAMPANA_OP_R]
          });
          links[0].push({
            link: "meds",
            texto: "MEDs",
            icon: <Store />,
            perms: [Roles.MED_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "ventas retail" &&
          !hasLink(links[0], "productos-retail")
        ) {
          links[0].push({
            link: "productos-retail",
            texto: "Ventas Retail",
            icon: <Store />,
            perms: [Roles.PRODUCTO_RETAIL_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "productos codificados" &&
          !hasLink(links[0], "productos-codificados")
        ) {
          links[0].push({
            link: "productos-codificados",
            texto: "Prod. c/códigos",
            icon: <Store />,
            perms: [Roles.PRODUCTO_CODIFICADO_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "acciones" &&
          !hasLink(links[2], "acciones")
        ) {
          if (!links[2]) {
            links[2] = [];
          }
          links[2].push({
            link: "acciones",
            texto: "Acciones",
            icon: <LocalActivityIcon />,
            perms: [Roles.ACCION_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "reconocimientos" &&
          !hasLink(links[2], "reconocimientos")
        ) {
          if (!links[2]) {
            links[2] = [];
          }
          links[2].push({
            link: "reconocimiento",
            texto: "Reconocimiento",
            icon: <LocalActivityIcon />,
            perms: [Roles.FORMULARIO_RECONOCIMIENTO_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "beneficios con cupón" &&
          !hasLink(links[0], "beneficios con cupón")
        ) {
          links[0].push({
            link: "beneficios",
            texto: "Beneficios",
            icon: <Receipt />,
            perms: [Roles.BENEFICIO_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "campañas genericas" &&
          !hasLink(links[0], "campanas")
        ) {
          links[0].push({
            link: "campanas",
            texto: "Campañas",
            icon: <FlagIcon />,
            perms: [Roles.CAMPANA_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "carga de codigos" &&
          !hasLink(links[0], "Carga de Codigos")
        ) {
          links[0].push({
            link: "carga-de-codigos",
            texto: "Carga de códigos",
            icon: <ViewListIcon />,
            perms: [Roles.CARGACODIGOS_W]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "catálogo códigos" &&
          !hasLink(links[0], "Catálogo Códigos")
        ) {
          links[0].push({
            link: "catalogoCodigos",
            texto: "Catálogo códigos",
            icon: <Receipt />,
            perms: [Roles.CATALOGO_CODIGO_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "moderación" &&
          !hasLink(links[0], "Moderación")
        ) {
          links[0].push({
            link: "moderacion",
            texto: "Moderación Fact P/puntos",
            icon: <Receipt />,
            perms: [Roles.MODERACION_W]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "carga de facturas" &&
          !hasLink(links[0], "Carga de facturas")
        ) {
          links[0].push({
            link: "carga-facturas",
            texto: "Facturas p/puntos",
            icon: <Receipt />,
            perms: [Roles.FACTURAS_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "factura por puntos" &&
          !hasLink(links[0], "Factura por Puntos")
        ) {
          links[0].push({
            link: "factura-por-puntos",
            texto: "Facturas por puntos",
            icon: <Receipt />,
            perms: [Roles.FACTURAS_POR_PUNTOS_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() ===
            "moderación factura por puntos" &&
          !hasLink(links[0], "Moderación Factura por Puntos")
        ) {
          links[0].push({
            link: "moderacion-factura-por-puntos",
            texto: "Moderación Fact P/puntos",
            icon: <Receipt />,
            perms: [Roles.MODERACION_FACTURA_POR_PUNTOS_W]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "tracking de links" &&
          !hasLink(links[0], "tracking de links")
        ) {
          links[0].push({
            link: "links-tracking",
            texto: "Tracking links",
            icon: <LinkIcon />,
            perms: [Roles.LINKS_TRACKING_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "tracking de sesiones" &&
          !hasLink(links[0], "tracking de sesiones")
        ) {
          links[0].push({
            link: "sesiones-tracking",
            texto: "Tracking sesiones",
            icon: <AccessTime />,
            perms: [Roles.SESSION_TRACKING_R]
          });
        }
        if (
          modulo.nombreGrupo.toLowerCase() === "api token" &&
          !hasLink(links[0], "api token")
        ) {
          links[0].push({
            link: "api-tokens",
            texto: "API Tokens",
            icon: <ApiIcon />,
            perms: [Roles.API_TOKEN_R]
          });
        }
      }
    });
  }
  return links;
};
