import { _t } from "js/utils/TranslationService";

//Tipos de campo que requieren un textField adicional
export const typesWithInput = ["Lista", "Lista_Multiple", "Lista_Autocomplete"];

//campos que requieren un textField adicional
export const keysWithInput = [
  "tasaDeAcreditacion",
  "limitePorFactura",
  "limiteDiario",
  "limiteMensual",
  "requiereComprobante",
  "requiereModeracion",
  "cargaTerceros",
  "regexNroFactura",
  "inicioTratamiento"
];

//Campos de configuración no renderizables en el formulario de carga de facturas
export const nonRenderableFields = [
  "tasaDeAcreditacion",
  "limiteDiario",
  "limitePorFactura",
  "limiteMensual",
  "incrementos",
  "requiereComprobante",
  "requiereModeracion",
  "cargaTerceros",
  "regexNroFactura",
  "inicioTratamiento"
];

export const inicioTratamientoFields = [
  {
    label: _t("Nombre completo médico"),
    key: "nombreCompletoMedico",
    tipo: "Texto",
    value: ""
  },
  {
    label: _t("Matrícula médico"),
    key: "matriculaMedico",
    tipo: "Texto",
    value: ""
  },
  {
    label: "Productos",
    key: "productos",
    tipo: "Lista_Autocomplete",
    value: []
  }
];
